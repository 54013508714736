<template>
  <b-container fluid>
    <b-row>
      <b-col cols="12" class="mb-4">
        <h1>Trends</h1>

        <b-card>
          <div slot="header">
            Filters
          </div>

          <b-container fluid>
            <b-row>
              <b-col class="mb-4">
                <span class="mr-2">Type:</span>
                <b-button-group>
                  <b-button
                      v-on:click="trendFilter.eventType = 'stream'"
                      v-bind:class="{ active: trendFilter.eventType == 'stream' }">
                    Stream
                  </b-button>
                  <b-button 
                      v-on:click="trendFilter.eventType = 'sale_track'"
                      v-bind:class="{ active: trendFilter.eventType == 'sale_track' }">
                    Track verkoop
                  </b-button>
                  <b-button 
                      v-on:click="trendFilter.eventType = 'sale_album'"
                      v-bind:class="{ active: trendFilter.eventType == 'sale_album' }">
                    Album verkoop
                  </b-button>
                </b-button-group>
              </b-col>

              <b-col cols="6" class="mb-4">
                <span class="mr-2">Time range:</span>

                <b-button-group class="mr-2">
                  <b-button
                      v-on:click="trendFilter.timerange = '48h'"
                      v-bind:class="{ active: trendFilter.timerange == '48h' }">
                    Afgelopen 48 uur
                  </b-button>
                  <b-button
                      v-on:click="trendFilter.timerange = '7d'"
                      v-bind:class="{ active: trendFilter.timerange == '7d' }">
                    Afgelopen week
                  </b-button>
                  <b-button
                      v-on:click="trendFilter.timerange = '1m'"
                      v-bind:class="{ active: trendFilter.timerange == '1m' }">
                    Afgelopen maand
                  </b-button>
                  <b-button
                      v-on:click="trendFilter.timerange = '1y'"
                      v-bind:class="{ active: trendFilter.timerange == '1y' }">
                    Afgelopen jaar
                  </b-button>
                  <b-button
                      v-on:click="trendFilter.timerange = 'custom'"
                      v-bind:class="{ active: trendFilter.timerange == 'custom' }">
                    Aangepast
                  </b-button>
                </b-button-group>

                <div v-if="trendFilter.timerange == 'custom'" class="mt-2">
                  Van:
                  <b-form-datepicker v-model="trendFilter.dateFrom" class="datepicker" placeholder="Selecteer datum"></b-form-datepicker>

                  Tot:
                  <b-form-datepicker v-model="trendFilter.dateTo" class="datepicker" placeholder="Selecteer datum"></b-form-datepicker>
                </div>
              </b-col>

              <b-col cols="2" class="mb-4">
                <span class="mr-2">Aantal:</span>

                <b-button-group class="mr-2">
                  <b-button
                      v-on:click="perPage = 5"
                      v-bind:class="{ active: perPage == 5 }">
                    5
                  </b-button>
                  <b-button
                      v-on:click="perPage = 10"
                      v-bind:class="{ active: perPage == 10 }">
                    10
                  </b-button>
                  <b-button
                      v-on:click="perPage = 25"
                      v-bind:class="{ active: perPage == 25 }">
                    25
                  </b-button>
                </b-button-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col class="mb-4">
                Tracks:
                <ApiLookup v-model="trendFilter.selectedTracks" source="tracks/lookup" />
              </b-col>

              <b-col class="mb-4">
                Albums:
                <ApiLookup v-model="trendFilter.selectedAlbums" source="albums/lookup" />
              </b-col>

              <b-col class="mb-4">
                Artiest:
                <ApiLookup v-model="trendFilter.selectedAttributions" source="attributions/lookup" />
              </b-col>
            </b-row>
          </b-container>
        </b-card>
      </b-col>

      <b-col cols="12" md="6" lg="4" xl="4" class="mb-4"><TrendList :trendFilter="trendFilter" :perPage="perPage" aggName="tracks" title="Top Tracks" column-title="Track" /></b-col>
      <b-col cols="12" md="6" lg="4" xl="4" class="mb-4"><TrendList :trendFilter="trendFilter" :perPage="perPage" aggName="albums" title="Top Albums" column-title="Album" /></b-col>
      <b-col cols="12" md="6" lg="4" xl="4" class="mb-4"><TrendList :trendFilter="trendFilter" :perPage="perPage" aggName="outlets" title="Top Portals" column-title="Portal" v-bind:showCount="false" /></b-col>
      <b-col cols="12" md="6" lg="4" xl="4" class="mb-4"><TrendList :trendFilter="trendFilter" :perPage="perPage" aggName="countries" title="Top landen" column-title="Land" /></b-col>
      <b-col cols="12" md="6" lg="4" xl="4" class="mb-4"><TrendList :trendFilter="trendFilter" :perPage="perPage" source="spotify" aggName="source" title="Bron" column-title="Bron" /></b-col>
      <b-col cols="12" md="6" lg="4" xl="4" class="mb-4"><TrendList :trendFilter="trendFilter" :perPage="perPage" source="spotify" aggName="gender" title="Geslacht" column-title="Geslacht"/></b-col>
    </b-row>

    <b-row>
      <b-col><TrendGraph :trendFilter="trendFilter" /></b-col>
    </b-row>
  </b-container>
</template>

<script>
  export default {
    name: 'Trends',
    components: {
      'TrendGraph': () => import('@/components/TrendGraph.vue'),
      'TrendList': () => import('@/components/TrendList.vue'),
      'ApiLookup': () => import('@/components/ApiLookup.vue'),
    },
    data () {
      return {
        trendFilter: {
          timerange: '7d',
          dateFrom: '',
          dateTo: '',
          eventType: 'stream',
          selectedTracks: [],
          selectedAlbums: [],
          selectedAttributions: [],
        },
        trendsLoading: false,
        perPage: 5,
      }
    },
    methods: {

    },
  }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .datepicker {
    display: inline-block;
  }
</style>
