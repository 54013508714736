var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { staticClass: "mb-4", attrs: { cols: "12" } },
            [
              _c("h1", [_vm._v("Trends")]),
              _c(
                "b-card",
                [
                  _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                    _vm._v(" Filters ")
                  ]),
                  _c(
                    "b-container",
                    { attrs: { fluid: "" } },
                    [
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { staticClass: "mb-4" },
                            [
                              _c("span", { staticClass: "mr-2" }, [
                                _vm._v("Type:")
                              ]),
                              _c(
                                "b-button-group",
                                [
                                  _c(
                                    "b-button",
                                    {
                                      class: {
                                        active:
                                          _vm.trendFilter.eventType == "stream"
                                      },
                                      on: {
                                        click: function($event) {
                                          _vm.trendFilter.eventType = "stream"
                                        }
                                      }
                                    },
                                    [_vm._v(" Stream ")]
                                  ),
                                  _c(
                                    "b-button",
                                    {
                                      class: {
                                        active:
                                          _vm.trendFilter.eventType ==
                                          "sale_track"
                                      },
                                      on: {
                                        click: function($event) {
                                          _vm.trendFilter.eventType =
                                            "sale_track"
                                        }
                                      }
                                    },
                                    [_vm._v(" Track verkoop ")]
                                  ),
                                  _c(
                                    "b-button",
                                    {
                                      class: {
                                        active:
                                          _vm.trendFilter.eventType ==
                                          "sale_album"
                                      },
                                      on: {
                                        click: function($event) {
                                          _vm.trendFilter.eventType =
                                            "sale_album"
                                        }
                                      }
                                    },
                                    [_vm._v(" Album verkoop ")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { staticClass: "mb-4", attrs: { cols: "6" } },
                            [
                              _c("span", { staticClass: "mr-2" }, [
                                _vm._v("Time range:")
                              ]),
                              _c(
                                "b-button-group",
                                { staticClass: "mr-2" },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      class: {
                                        active:
                                          _vm.trendFilter.timerange == "48h"
                                      },
                                      on: {
                                        click: function($event) {
                                          _vm.trendFilter.timerange = "48h"
                                        }
                                      }
                                    },
                                    [_vm._v(" Afgelopen 48 uur ")]
                                  ),
                                  _c(
                                    "b-button",
                                    {
                                      class: {
                                        active:
                                          _vm.trendFilter.timerange == "7d"
                                      },
                                      on: {
                                        click: function($event) {
                                          _vm.trendFilter.timerange = "7d"
                                        }
                                      }
                                    },
                                    [_vm._v(" Afgelopen week ")]
                                  ),
                                  _c(
                                    "b-button",
                                    {
                                      class: {
                                        active:
                                          _vm.trendFilter.timerange == "1m"
                                      },
                                      on: {
                                        click: function($event) {
                                          _vm.trendFilter.timerange = "1m"
                                        }
                                      }
                                    },
                                    [_vm._v(" Afgelopen maand ")]
                                  ),
                                  _c(
                                    "b-button",
                                    {
                                      class: {
                                        active:
                                          _vm.trendFilter.timerange == "1y"
                                      },
                                      on: {
                                        click: function($event) {
                                          _vm.trendFilter.timerange = "1y"
                                        }
                                      }
                                    },
                                    [_vm._v(" Afgelopen jaar ")]
                                  ),
                                  _c(
                                    "b-button",
                                    {
                                      class: {
                                        active:
                                          _vm.trendFilter.timerange == "custom"
                                      },
                                      on: {
                                        click: function($event) {
                                          _vm.trendFilter.timerange = "custom"
                                        }
                                      }
                                    },
                                    [_vm._v(" Aangepast ")]
                                  )
                                ],
                                1
                              ),
                              _vm.trendFilter.timerange == "custom"
                                ? _c(
                                    "div",
                                    { staticClass: "mt-2" },
                                    [
                                      _vm._v(" Van: "),
                                      _c("b-form-datepicker", {
                                        staticClass: "datepicker",
                                        attrs: {
                                          placeholder: "Selecteer datum"
                                        },
                                        model: {
                                          value: _vm.trendFilter.dateFrom,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.trendFilter,
                                              "dateFrom",
                                              $$v
                                            )
                                          },
                                          expression: "trendFilter.dateFrom"
                                        }
                                      }),
                                      _vm._v(" Tot: "),
                                      _c("b-form-datepicker", {
                                        staticClass: "datepicker",
                                        attrs: {
                                          placeholder: "Selecteer datum"
                                        },
                                        model: {
                                          value: _vm.trendFilter.dateTo,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.trendFilter,
                                              "dateTo",
                                              $$v
                                            )
                                          },
                                          expression: "trendFilter.dateTo"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { staticClass: "mb-4", attrs: { cols: "2" } },
                            [
                              _c("span", { staticClass: "mr-2" }, [
                                _vm._v("Aantal:")
                              ]),
                              _c(
                                "b-button-group",
                                { staticClass: "mr-2" },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      class: { active: _vm.perPage == 5 },
                                      on: {
                                        click: function($event) {
                                          _vm.perPage = 5
                                        }
                                      }
                                    },
                                    [_vm._v(" 5 ")]
                                  ),
                                  _c(
                                    "b-button",
                                    {
                                      class: { active: _vm.perPage == 10 },
                                      on: {
                                        click: function($event) {
                                          _vm.perPage = 10
                                        }
                                      }
                                    },
                                    [_vm._v(" 10 ")]
                                  ),
                                  _c(
                                    "b-button",
                                    {
                                      class: { active: _vm.perPage == 25 },
                                      on: {
                                        click: function($event) {
                                          _vm.perPage = 25
                                        }
                                      }
                                    },
                                    [_vm._v(" 25 ")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { staticClass: "mb-4" },
                            [
                              _vm._v(" Tracks: "),
                              _c("ApiLookup", {
                                attrs: { source: "tracks/lookup" },
                                model: {
                                  value: _vm.trendFilter.selectedTracks,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.trendFilter,
                                      "selectedTracks",
                                      $$v
                                    )
                                  },
                                  expression: "trendFilter.selectedTracks"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { staticClass: "mb-4" },
                            [
                              _vm._v(" Albums: "),
                              _c("ApiLookup", {
                                attrs: { source: "albums/lookup" },
                                model: {
                                  value: _vm.trendFilter.selectedAlbums,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.trendFilter,
                                      "selectedAlbums",
                                      $$v
                                    )
                                  },
                                  expression: "trendFilter.selectedAlbums"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { staticClass: "mb-4" },
                            [
                              _vm._v(" Artiest: "),
                              _c("ApiLookup", {
                                attrs: { source: "attributions/lookup" },
                                model: {
                                  value: _vm.trendFilter.selectedAttributions,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.trendFilter,
                                      "selectedAttributions",
                                      $$v
                                    )
                                  },
                                  expression: "trendFilter.selectedAttributions"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            {
              staticClass: "mb-4",
              attrs: { cols: "12", md: "6", lg: "4", xl: "4" }
            },
            [
              _c("TrendList", {
                attrs: {
                  trendFilter: _vm.trendFilter,
                  perPage: _vm.perPage,
                  aggName: "tracks",
                  title: "Top Tracks",
                  "column-title": "Track"
                }
              })
            ],
            1
          ),
          _c(
            "b-col",
            {
              staticClass: "mb-4",
              attrs: { cols: "12", md: "6", lg: "4", xl: "4" }
            },
            [
              _c("TrendList", {
                attrs: {
                  trendFilter: _vm.trendFilter,
                  perPage: _vm.perPage,
                  aggName: "albums",
                  title: "Top Albums",
                  "column-title": "Album"
                }
              })
            ],
            1
          ),
          _c(
            "b-col",
            {
              staticClass: "mb-4",
              attrs: { cols: "12", md: "6", lg: "4", xl: "4" }
            },
            [
              _c("TrendList", {
                attrs: {
                  trendFilter: _vm.trendFilter,
                  perPage: _vm.perPage,
                  aggName: "outlets",
                  title: "Top Portals",
                  "column-title": "Portal",
                  showCount: false
                }
              })
            ],
            1
          ),
          _c(
            "b-col",
            {
              staticClass: "mb-4",
              attrs: { cols: "12", md: "6", lg: "4", xl: "4" }
            },
            [
              _c("TrendList", {
                attrs: {
                  trendFilter: _vm.trendFilter,
                  perPage: _vm.perPage,
                  aggName: "countries",
                  title: "Top landen",
                  "column-title": "Land"
                }
              })
            ],
            1
          ),
          _c(
            "b-col",
            {
              staticClass: "mb-4",
              attrs: { cols: "12", md: "6", lg: "4", xl: "4" }
            },
            [
              _c("TrendList", {
                attrs: {
                  trendFilter: _vm.trendFilter,
                  perPage: _vm.perPage,
                  source: "spotify",
                  aggName: "source",
                  title: "Bron",
                  "column-title": "Bron"
                }
              })
            ],
            1
          ),
          _c(
            "b-col",
            {
              staticClass: "mb-4",
              attrs: { cols: "12", md: "6", lg: "4", xl: "4" }
            },
            [
              _c("TrendList", {
                attrs: {
                  trendFilter: _vm.trendFilter,
                  perPage: _vm.perPage,
                  source: "spotify",
                  aggName: "gender",
                  title: "Geslacht",
                  "column-title": "Geslacht"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [_c("TrendGraph", { attrs: { trendFilter: _vm.trendFilter } })],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }